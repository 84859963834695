<template>
  <div class="listCardItem">
    <div
      v-if="historyItem.BetType === BET_NORMAL_TYPE"
      :class="isDelete ? 'DeleteBG' : ''"
      style="cursor: pointer"
      @click="normalHeaderClick"
      :set="(betitem = historyItem.dataBet[0])"
    >
      <div class="cardHeaderRow">
        <div
          v-if="
            betitem.GameType === GAME_PLAYING_TYPE &&
            GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
            GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
          "
          class="ScoreColor"
        >
          {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
        </div>
        <div class="playMethodName">
          {{ betitem.WagerPosName }}
        </div>
        <div
          v-if="!GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
          class="playMethodNameSupport"
        >
          {{ betitem.CutLine }}
        </div>
        <div class="at"> @ </div>
        <div class="playBetOdd">
          {{ showOddValue(betitem.PayoutOddsStr) }}
        </div>
        <div class="rightArrowBlock">
          <i :class="arrowIconJudge(historyItem.isCollapse)" />
        </div>
      </div>
      <div class="cardContentBlock" :class="isDelete ? 'fatherTextDecoration' : ''">
        <div class="cardContentBlockRow">
          {{ betitem.CatName }}
          <template v-if="betitem.GameType === GAME_PLAYING_TYPE && betitem.WagerGrpID !== 35">
            {{ betitem.GameTypeName }}
          </template>
          <template v-if="betitem.ItemName"> - [{{ betitem.ItemName }}] </template>
          <template v-else> - [{{ betitem.LeagueName }}] </template>
        </div>
        <div v-if="betitem.CatID === GAME_HORSE_DOG_CAT" class="cardContentBlockRow">
          <div class="cardContentBlockRowText">
            <span v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</span>
            {{ betitem.HomeTeam }}
          </div>
        </div>
        <div v-else-if="betitem.CatID === GAME_LOTTERY_CAT" class="cardContentBlockRow">
          <div class="cardContentBlockRowText">
            <template v-if="betitem.HomeTeam">
              <span v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</span>
              {{ betitem.HomeTeam }}
            </template>
            <template v-else-if="betitem.AwayTeam">
              <span v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</span>
              {{ betitem.AwayTeam }}
            </template>
          </div>
        </div>
        <div v-else-if="betitem.CatID === GAME_INDEX_CAT" class="cardContentBlockRow">
          <div class="cardContentBlockRowText">
            <span v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</span>
            {{ betitem.AwayTeam }}
          </div>
        </div>
        <template v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)">
          <div v-if="betitem.HomeTeam" class="cardContentBlockRow">
            <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
            <div class="cardContentBlockRowText">
              {{ betitem.HomeTeam }}
            </div>
            <div class="cardContentBlockRowText HomeTeamSign">({{ $t('Common.Home') }})</div>
          </div>
          <div v-else-if="betitem.AwayTeam" class="cardContentBlockRow">
            <div v-if="isSettlement" class="ScoreColor">[{{ betitem.AwayScore }}]</div>
            <div class="cardContentBlockRowText">
              {{ betitem.AwayTeam }}
            </div>
          </div>
        </template>
        <div
          v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
          class="cardContentBlockRow"
        >
          <div class="cardContentBlockRowText">
            {{ betitem.HomeTeam }}
          </div>
        </div>
        <div v-else class="cardContentBlockRow">
          <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
          <div class="cardContentBlockRowText">
            {{ betitem.HomeTeam }}
          </div>
          <div class="cardContentBlockRowText HomeTeamSign">({{ $t('Common.Home') }})</div>
          <div class="vs"> vs </div>
          <div v-if="isSettlement" class="ScoreColor">[{{ betitem.AwayScore }}]</div>
          <div class="cardContentBlockRowText">
            {{ betitem.AwayTeam }}
          </div>
        </div>
        <template v-if="historyItem.isCollapse">
          <div class="cardContentBlockRow">
            {{ $t('HistoryRecord.League') }} : {{ betitem.LeagueName }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('HistoryRecord.Bet') }} : {{ historyItem.BetTimeStr }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.Game') }} : {{ betitem.ScheduleTimeStr.slice(0, 16) }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.BetID') }} : {{ historyItem.TicketID }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.Odd') }} : {{ $t('GamesSetup.NotIncludePrincipal') }}
          </div>
        </template>
        <div v-if="betitem.ItemStatusContent" class="cardContentBlockRow">
          <span style="color: red">({{ betitem.ItemStatusContent }})</span>
        </div>
        <div class="cardContentBlockRow">
          <div class="cardContentBlockWithHalfRow">
            {{ $t('Common.Bet') }} : {{ toCurrency(historyItem.Amount) }}
          </div>
          <div v-if="isSettlement" class="cardContentBlockWithHalfRow">
            {{ $t('Common.Result') }} :
            <span class="resultText" :style="[resultTextColorJudge(historyItem.ResultAmount)]">
              {{ toCurrency(historyItem.ResultAmount) }}
            </span>
          </div>
          <div v-else class="cardContentBlockWithHalfRow">
            {{ $t('BetViewList.HighBack') }} :
            {{ toCurrency(historyItem.ToWin) }}
          </div>
        </div>
        <div v-if="historyItem.Remarks" class="Remarks">
          {{ historyItem.Remarks }}
        </div>
        <img
          v-if="historyItem.SellFlag"
          class="sell-icon info"
          src="@/assets/icon-sell.gif"
          :title="historyItem.TicketTypeName"
        />
      </div>
    </div>
    <!-- 過關 -->
    <div v-else :class="isDelete ? 'DeleteBG' : ''" style="cursor: pointer" @click="strayClick">
      <div class="cardHeaderRow">
        <div class="strayContentBlockRow">
          <template v-if="historyItem.BetType === BET_COMBO_PARLAY_TYPE">
            <div>
              {{ $t('Common.Stray97') }}
            </div>
            <div class="strayTitleInfoText">
              {{ historyItem.Option.split('-')[0] }}{{ $t('Common.string') }}1 x
              {{
                getBet97Sets(Number(historyItem.Option.split('-')[0]), historyItem.dataBet.length)
              }}
            </div>
          </template>
          <template v-else-if="historyItem.BetType === BET_SUPER8_PARLAY_TYPE">
            <div>
              {{ $t('super8.super8') }}
            </div>
          </template>
          <template v-else-if="historyItem.BetType === BET_PARLAYL_TYPE">
            <div>
              {{ $t('Common.Stray') }}
            </div>
            <div class="strayTitleInfoText">
              {{ historyItem.dataBet.length }}{{ $t('Common.string') }}1
            </div>
          </template>
        </div>
        <div class="rightArrowBlock">
          <i :class="arrowIconJudge(historyItem.isCollapse)" />
        </div>
      </div>
      <template v-if="historyItem.isCollapse">
        <div
          v-for="(betitem, index) in historyItem.dataBet"
          :key="index"
          class="strayDetailBlock"
          :class="isStrayDelete(betitem) ? 'strayDetailBlockDelete' : ''"
        >
          <div class="strayDetailTitle">
            <span
              v-if="
                betitem.GameType === GAME_PLAYING_TYPE &&
                GAME_FOOTBALL_CATS.includes(betitem.CatID) &&
                GAME_HANDICAP_WAGERS.includes(betitem.WagerTypeID)
              "
              class="ScoreColor"
              style="display: inline"
            >
              {{ `(${betitem.CurHomeScore}:${betitem.CurAwayScore})` }}
            </span>
            <span class="betPosName">
              {{ betitem.WagerPosName }}
            </span>
            <span class="cutLine">
              {{ betitem.CutLine }}
            </span>
            <template v--if="historyItem.BetType !== BET_SUPER8_PARLAY_TYPE">
              @
              <span class="cutLine">
                {{ betitem.PayoutOddsStr }}
              </span>
            </template>
          </div>
          <div class="strayDetailLine" />
          <div class="strayDetailContentBlock">
            <div class="strayDetailContentBlockRow">
              {{ betitem.CatName }}
              <template v-if="betitem.ItemName"> - [{{ betitem.ItemName }}] </template>
            </div>
            <div class="strayDetailContentBlockRow">
              {{ betitem.LeagueName }}
            </div>
            <div v-if="betitem.CatID === GAME_HORSE_DOG_CAT" class="strayDetailContentBlockRow">
              <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
              <div> {{ betitem.HomeTeam }} </div>
            </div>
            <div v-else-if="betitem.CatID === GAME_LOTTERY_CAT" class="strayDetailContentBlockRow">
              <template v-if="betitem.HomeTeam">
                <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
                <div> {{ betitem.HomeTeam }} </div>
              </template>
              <template v-else-if="betitem.AwayTeam">
                <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
                <div> {{ betitem.AwayTeam }} </div>
              </template>
            </div>
            <div v-else-if="betitem.CatID === GAME_INDEX_CAT" class="strayDetailContentBlockRow">
              <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
              <div>
                {{ betitem.AwayTeam }}
              </div>
            </div>
            <template v-else-if="GAME_ONE_TEAM_SCORE_WAGERS.includes(betitem.WagerTypeID)">
              <div v-if="betitem.HomeTeam" class="cardContentBlockRow">
                <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
                <div class="cardContentBlockRowText">
                  {{ betitem.HomeTeam }}
                </div>
                <div class="cardContentBlockRowText HomeTeamSign">({{ $t('Common.Home') }})</div>
              </div>
              <div v-else-if="betitem.AwayTeam" class="cardContentBlockRow">
                <div v-if="isSettlement" class="ScoreColor">[{{ betitem.AwayScore }}]</div>
                <div class="cardContentBlockRowText">
                  {{ betitem.AwayTeam }}
                </div>
              </div>
            </template>
            <div
              v-else-if="GAME_CHAMPION_WAGERS.includes(betitem.WagerTypeID)"
              class="cardContentBlockRow"
            >
              <div class="cardContentBlockRowText">
                {{ betitem.HomeTeam }}
              </div>
            </div>
            <div v-else class="strayDetailContentBlockRow">
              <div v-if="isSettlement" class="ScoreColor">[{{ betitem.HomeScore }}]</div>
              <div> {{ betitem.HomeTeam }} </div>
              <div class="HomeTeamSign">({{ $t('Common.Home') }})</div>
              <div class="vs">vs</div>
              <div v-if="isSettlement" class="ScoreColor">[{{ betitem.AwayScore }}]</div>
              <div class="strayDetailContentBlockRowTeam">
                {{ betitem.AwayTeam }}
              </div>
            </div>
            <div class="strayDetailContentBlockRow">
              {{ $t('Common.Game') }} :
              <span class="startGameTime">{{ betitem.ScheduleTimeStr }}</span>
            </div>
            <div v-if="betitem.ItemStatusContent" class="strayDetailContentBlockRow">
              <span style="color: red">({{ betitem.ItemStatusContent }})</span>
            </div>
          </div>
        </div>
      </template>
      <div class="cardContentBlock" :class="isDelete ? 'fatherTextDecoration' : ''">
        <template v-if="historyItem.isCollapse">
          <div class="cardContentBlockRow">
            {{ $t('HistoryRecord.Bet') }} : {{ historyItem.BetTimeStr }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.BetID') }} : {{ historyItem.TicketID }}
          </div>
          <div class="cardContentBlockRow">
            {{ $t('Common.Odd') }} : {{ $t('GamesSetup.NotIncludePrincipal') }}
          </div>
          <div class="cardContentBlockRow">
            <template v-if="historyItem.BetType === BET_COMBO_PARLAY_TYPE">
              <div
                v-if="['en', 'vi'].includes(i18nLocale)"
                class="bet97-link"
                @click="openBet97(historyItem, isSettlement ? 1 : 0)"
                style="text-align: left"
              >
                {{ historyItem.Option.split('-')[0] }}{{ $t('Common.string') }}1 x
                {{
                  getBet97Sets(Number(historyItem.Option.split('-')[0]), historyItem.dataBet.length)
                }}
                = {{ historyItem.Amount }}
                <br />
                (
                {{ $t('Common.EachZu') }}
                {{
                  historyItem.Amount /
                  getBet97Sets(Number(historyItem.Option.split('-')[0]), historyItem.dataBet.length)
                }}
                {{ $t('Common.Dollar') }}
                x
                {{
                  getBet97Sets(Number(historyItem.Option.split('-')[0]), historyItem.dataBet.length)
                }}
                {{ $t('Common.Group') }}
                )
              </div>
              <span v-else class="bet97-link" @click="openBet97(historyItem, isSettlement ? 1 : 0)">
                {{ historyItem.Option.split('-')[0] }}{{ $t('Common.string') }}1 x
                {{
                  getBet97Sets(Number(historyItem.Option.split('-')[0]), historyItem.dataBet.length)
                }}
                {{
                  `(${$t('Common.EachZu')}${
                    historyItem.Amount /
                    getBet97Sets(
                      Number(historyItem.Option.split('-')[0]),
                      historyItem.dataBet.length
                    )
                  }${$t('Common.Dollar')} x ${getBet97Sets(
                    Number(historyItem.Option.split('-')[0]),
                    historyItem.dataBet.length
                  )}${$t('Common.Group')}) = ${historyItem.Amount}`
                }}
              </span>
            </template>
            <template v-else> {{ historyItem.dataBet.length }}{{ $t('Common.string') }}1 </template>
          </div>
        </template>

        <template v-if="['vi'].includes(i18nLocale)">
          <div class="cardContentBlockRow">
            {{ $t('Common.Bet') }} : {{ toCurrency(historyItem.Amount) }}
          </div>
          <div v-if="isSettlement" class="cardContentBlockRow">
            {{ $t('Common.Result') }} :
            <span class="resultText" :style="[resultTextColorJudge(historyItem.ResultAmount)]">
              {{ toCurrency(historyItem.ResultAmount) }}
            </span>
          </div>
          <div v-else class="cardContentBlockRow">
            {{ $t('BetViewList.HighBack') }} :
            {{ toCurrency(historyItem.ToWin) }}
          </div>
        </template>
        <template v-else>
          <div class="cardContentBlockRow">
            <div class="cardContentBlockWithHalfRow">
              {{ $t('Common.Bet') }} : {{ toCurrency(historyItem.Amount) }}
            </div>
            <div v-if="isSettlement" class="cardContentBlockWithHalfRow">
              {{ $t('Common.Result') }} :
              <span class="resultText" :style="[resultTextColorJudge(historyItem.ResultAmount)]">
                {{ toCurrency(historyItem.ResultAmount) }}
              </span>
            </div>
            <div v-else class="cardContentBlockWithHalfRow">
              {{ $t('BetViewList.HighBack') }} :
              {{ toCurrency(historyItem.ToWin) }}
            </div>
          </div>
        </template>

        <div v-if="historyItem.Remarks" class="Remarks">
          {{ historyItem.Remarks }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    GAME_PLAYING_TYPE,
    GAME_HORSE_DOG_CAT,
    GAME_LOTTERY_CAT,
    GAME_INDEX_CAT,
    GAME_FOOTBALL_CATS,
    GAME_HANDICAP_WAGERS,
    GAME_ONE_TEAM_SCORE_WAGERS,
    GAME_CHAMPION_WAGERS,
    BET_NORMAL_TYPE,
    BET_COMBO_PARLAY_TYPE,
    BET_SUPER8_PARLAY_TYPE,
    BET_PARLAYL_TYPE,
  } from '@/config';
  import { toCurrency } from '@/config/balance';
  import { getBet97Sets } from '@/config/bet';
  import { apiPostSell } from '@/api/game';

  export default {
    components: {},
    props: {
      historyItem: {
        type: Object,
        default: () => {},
      },
      isSettlement: {
        type: Boolean,
      },
    },
    data() {
      return {
        GAME_PLAYING_TYPE,
        GAME_HORSE_DOG_CAT,
        GAME_LOTTERY_CAT,
        GAME_INDEX_CAT,
        GAME_FOOTBALL_CATS,
        GAME_HANDICAP_WAGERS,
        GAME_ONE_TEAM_SCORE_WAGERS,
        GAME_CHAMPION_WAGERS,
        BET_NORMAL_TYPE,
        BET_COMBO_PARLAY_TYPE,
        BET_SUPER8_PARLAY_TYPE,
        BET_PARLAYL_TYPE,
      };
    },
    computed: {
      isDelete() {
        return this.historyItem?.StatusType === 'D' || this.historyItem?.StatusType === 'V';
      },
      sellings() {
        return this.$store.state.History.sellings;
      },
      i18nLocale() {
        return this.$store.state.i18nLocale;
      },
    },
    methods: {
      isStrayDelete(bet) {
        return bet?.ItemStatus === 'D' || bet?.ItemStatus === 'V';
      },
      toCurrency,
      isShowScore(catID, GameType) {
        return (catID === 1 || catID === 31) && GameType === 2;
      },
      strayClick() {
        this.historyItem.isCollapse = !this.historyItem.isCollapse;
        this.$forceUpdate();
      },
      normalHeaderClick() {
        this.historyItem.isCollapse = !this.historyItem.isCollapse;
        this.$forceUpdate();
      },
      showOddValue(oddValue) {
        if (this.includePrincipal) {
          return this.$lib.trunc(parseFloat(oddValue) + 1);
        } else {
          return oddValue;
        }
      },
      arrowIconJudge(isCollapse) {
        if (isCollapse) {
          return 'el-icon-arrow-up';
        } else {
          return 'el-icon-arrow-down';
        }
      },
      resultTextColorJudge(value) {
        const color = value > 0 ? 'green' : value < 0 ? 'red' : 'black';
        return { color };
      },
      selling(item) {
        // console.log(item);
        const { TicketID } = item;
        this.sellings.set(TicketID, { TicketID, status: 1, info: '' });
        this.$forceUpdate();
      },
      submitSelling(item) {
        const { TicketID } = item;
        const sell = this.sellings.get(TicketID);
        if (sell) {
          sell.status = 2;
          sell.info = '兌現處理中';
        }
        this.$forceUpdate();
        const time1 = Date.now();
        const updateInfo = () => {
          if (sell.info.endsWith('...')) {
            sell.info = '兌現處理中';
          } else {
            sell.info += '.';
          }
          this.$forceUpdate();
          const time2 = Date.now();
          const interval = time2 - time1;
          if (interval < 2000) {
            setTimeout(updateInfo, 400);
          } else {
            apiPostSell({ TicketID })
              .then((response) => {
                // console.log(response);
                if (sell) {
                  sell.status = 3;
                  sell.info = '兌現成功';
                }
                this.$forceUpdate();
                setTimeout(() => {
                  this.sellings.delete(TicketID);
                  /*
                  this.$store.dispatch('BetCart/apiGetTicket', {
                    isset: false,
                    pagesize: 101,
                    page: 1,
                  }); */
                }, 4000);
              })
              .catch(() => {
                if (sell) {
                  sell.status = 4;
                  sell.info = '兌現失敗';
                }
                this.$forceUpdate();
              });
          }
        };
        setTimeout(updateInfo, 400);
      },
      cancelSelling(item) {
        const { TicketID } = item;
        this.sellings.delete(TicketID);
        this.$forceUpdate();
      },
      getBet97Sets,
      openBet97(item, type) {
        const { href: url } = this.$router.resolve({
          path: `/HistoryRecord97/${item.TicketID}/${type}`,
        });
        if (url) window.open(url, 'bet97');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './ListCardItem.scss';
  .strayDetailContentBlockRow {
    .HomeTeamSign {
      margin-left: 5px;
    }
    .vs {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  .vs {
    margin-right: 5px;
  }

  .sell {
    padding: 4px 8px;
  }
  .selling {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px 16px;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    background-color: #dddddd;
  }
  .selling-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 0 4px 2px;
  }
  .selling-content {
    padding: 4px;
  }
  .selling-btns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    gap: 16px;
  }
  .selling-confirm {
    padding: 4px 16px;
    border: 1px solid #34b28d;
    border-radius: 8px;
    box-shadow: inset 1px 1px 1px rgb(255, 255, 255, 0.3);
    background-color: #06a378;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #088e6a;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .selling-cancel {
    padding: 4px 16px;
    border: 1px solid #ff7074;
    border-radius: 8px;
    box-shadow: inset 1px 1px 1px rgb(255, 255, 255, 0.3);
    background-color: #f45358;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #d84a4e;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .selling-loading {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    &::v-deep .el-loading-mask {
      background-color: transparent !important;
    }
    &::v-deep .el-loading-spinner {
      top: 0 !important;
      margin-top: 0 !important;
    }
    &::v-deep .el-loading-spinner .circular {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .selling-info {
    display: inline-block;
  }
  .sell-btn {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border: 1px solid #cdcdcd;
    border-radius: 8px;
    background: linear-gradient(#f5f3f5, #d3d3d3);
    cursor: pointer;
    &:hover {
      background: linear-gradient(#d3d3d3, #f5f3f5);
    }
  }
  .sell-icon {
    width: 20px;
    height: 20px;

    &.info {
      position: absolute;
      right: 8px;
      top: 36px;
    }
  }
  .sell-text {
    font-size: 13px;
    color: #7d7d7d;
  }
  .sell-amount {
    padding-top: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #666666;
  }
  .bet97-link {
    color: #409eff;
    cursor: pointer;
  }
</style>
