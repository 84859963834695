<template>
  <table class="GameTableHeader">
    <tbody class="GameTableBody">
      <td class="FirstCatNameBlock" :class="'c' + selectCatID" @click="clickArrow">
        <div class="leftArrow">
          <div class="leftArrowBlock">
            <i :class="arrowIconJudge" />
          </div>
          {{ CatName }}
        </div>
        <div class="dropDownContainer" @click.stop>
          <el-dropdown v-if="isShowMenu" trigger="click" class="dropDown" @command="dropDownEvent">
            <span class="el-dropdown-link">
              {{ dropDownOption.find((it) => it.value === isFullGame).label }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, key) in dropDownOption"
                :key="key"
                :command="item.value"
                :class="item.value === isFullGame ? 'DefaultFocus' : ''"
              >
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </td>
      <template v-if="isBoldGame"> </template>
      <template v-else>
        <td v-for="(it, key) in BestHeadWithFilterLimit" :key="key" class="GameTableHeaderOtherTD">
          <div class="borderWhiteBlock"></div>
          {{ it.showName }}
          <div></div>
        </td>
        <td v-if="isShowMore" class="GameTableHeaderMoreTD">
          <div class="borderWhiteBlock" />
          {{ $t('Common.More') }}
          <div />
        </td>
      </template>
    </tbody>
  </table>
</template>

<script>
  import { GAME_CHAMPION_CAT } from '@/config/index.js';

  export default {
    props: {
      top: {
        type: Boolean,
        default: false,
      },
      tableItem: {
        type: Object,
        default: null,
      },
      CatID: {
        type: Number,
      },
      CatName: {
        type: String,
      },
      BestHead: {
        type: Array,
      },
      isShowMoreGameEntryBtn: {
        type: Boolean,
      },
      isCollapse: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: '',
      },
      columnLimit: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        isFullGame: true,
        dropDownOption: [
          { value: true, label: this.$t('Common.FullGame') },
          { value: false, label: this.$t('common.halfGame') },
        ],
      };
    },
    computed: {
      gameStore() {
        return this.$store.state.Game;
      },
      selectGameType() {
        return this.gameStore.selectGameType;
      },
      selectCatID() {
        return this.gameStore.selectCatID;
      },
      selectWagerTypeKey() {
        return this.gameStore.selectWagerTypeKey;
      },
      boldFilterType() {
        return this.gameStore.boldFilterType;
      },
      BestHeadWithFilterLimit() {
        const { BestHead, columnLimit, selectWagerTypeKey, isShowMenu, boldFilterType } = this;
        const [BestHead0, BestHead1, BestHead2] = BestHead;
        if (isShowMenu) {
          if (boldFilterType) {
            return BestHead1.filter((item, index) => index < columnLimit);
          } else {
            return BestHead2.filter((item, index) => index < columnLimit);
          }
        }
        // 入球數 半全場
        else if ([4, 5].includes(selectWagerTypeKey)) {
          return BestHead0;
        } else {
          return BestHead0.filter((item, index) => index < columnLimit);
        }
      },
      isShowMoreGame() {
        return this.$store.state.MoreGame.isShowMoreGame;
      },
      arrowIconJudge() {
        if (this.isCollapse) {
          return 'el-icon-arrow-down';
        } else {
          return 'el-icon-arrow-up';
        }
      },
      isBoldGame() {
        const { selectCatID, selectWagerTypeKey } = this;
        return [1, 31].includes(selectCatID) && selectWagerTypeKey === 3;
      },
      isShowMenu() {
        const { selectCatID, selectWagerTypeKey } = this;
        switch (selectCatID) {
          // 足球
          case 1:
          case 31:
            switch (selectWagerTypeKey) {
              case 2:
                return true;
              default:
                return false;
            }
          // 籃球
          case 3:
          case 102:
            switch (selectWagerTypeKey) {
              // 單隊總分
              case 6:
                return true;
              default:
                return false;
            }
          default:
            return false;
        }
      },
      isShowMore() {
        const { selectCatID } = this;
        if (selectCatID === GAME_CHAMPION_CAT) return false;
        return this.isShowMoreGameEntryBtn;
      },
    },
    watch: {
      boldFilterType: {
        handler() {
          this.isFullGame = this.boldFilterType;
        },
        immediate: true,
      },
    },
    methods: {
      clickArrow() {
        this.$emit('ArrowClick');
      },
      dropDownEvent(val) {
        this.$store.commit('Game/setBoldFilterType', val);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './GameTable.scss';

  .GameTableHeader {
    .GameTableBody {
      height: 100%;
      .FirstCatNameBlock {
        height: 100%;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        padding-right: 10px;
        padding-left: 12px;
        .leftArrow {
          display: flex;
          align-items: center;
          .leftArrowBlock {
            width: 25px;
          }
        }
        .dropDownContainer {
          display: inline-block;
          height: 100%;
          .dropDown {
            color: white;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
</style>
